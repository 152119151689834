<template>
  <window-content v-if="!loading">
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="vistoriador.id" disable />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 260px">
          <status-select label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                         v-model="vistoriador.status" />
        </e-col>

      </e-row>
      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              :label="vistoriador.pessoa.type === 1 ? 'Nome completo:' : 'Razão social:'"
              label-width="110px"
          >
            <pessoa-input-v2 v-model="vistoriador.pessoa" disable disabled />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
      <erp-tab-item name="logs" v-if="vistoriador.id">Logs</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" :class="{'flex col-grow': tabActive === 'logs' || tabActive === 'arremates'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row v-if="vistoriador.pessoa && vistoriador.pessoa.id" mr class="">
              <e-col>
                <prestador-assinatura ref="assinatura" :pessoa="vistoriador.pessoa" />
                <div class="m-t-xs">Deixe a assinatura em branco para utilizar a assinatura digital com autenticidade.</div>
              </e-col>
            </e-row>
            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="tl"
                    label="Descrição interna:"
                    class="wrap label-wrap"
                >
                  <textarea class="w-full erp-input wrapper-xs" rows="4"
                            v-model="vistoriador.observacao"></textarea>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="tl"
                    label="Formulário de vistoria específico:"
                    class="wrap label-wrap"
                >
                  <form-input v-model="vistoriador.padraoForm" @changeDocumento="changeDocumento"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
          <e-col class="m-l-xxl" style="min-width: 216px; max-width: 216px">
            <pessoa-foto ref="foto" :pessoa="vistoriador.pessoa" />
          </e-col>
        </e-row>
        <e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm">Acesso</div>
            <acesso :entidade="vistoriador" />
          </e-col>
        </e-row>
      </div>
      <div v-if="vistoriador.id && tabActive === 'logs'" class="wrapper flex col-grow">
        <log :usuario="vistoriador" type="vistoriador" />
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-loading :status="status" v-else />
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import {date} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {
  find,
  newVistoriador,
  updateVistoriador
} from '../../../../domain/vistoriador/services/index'
import DatetimeInput from '../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import StatusSelect from '../../../arrematante/components/include/StatusArrematanteSelect'
import PatioInput from '../../../patio/helpers/input/PatioInput'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import TipoVistoriadorSelect from '../../../arrematante/components/include/TipoArrematanteSelect'
import Acesso from '../include/Acesso'
import Log from '../../../usuario/components/include/Log'
import PessoaInputV2 from "components/pessoa/components/include/PessoaInputV2.vue";
import PessoaFoto from "components/pessoa/components/include/Foto.vue"
import PrestadorAssinatura from "components/vistoriador/components/include/Assinatura.vue";
import FormInput from "components/forms/components/include/FormInput.vue";

let mockVistoriador = {
  id: null,
  apelido: null,
  podeReceberCotacoes: true,
  atendeQualquerComitente: false,
  newsletter: true,
  score: null,
  observacao: null,
  pessoa: {
    id: null,
    name: null,
    document: null,
    type: 1
  },
  enviarBoasVindas: true,
  solicitarCriacaoSenha: true,
  padraoForm: null,
  status: 0
}

export default {
  name: 'VistoriadorWindow',
  directives: {money: VMoney},
  props: ['id'],
  data () {
    return {
      money: REAL_BRL,
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      deleteFoto: false,
      vistoriador: JSON.parse(JSON.stringify(mockVistoriador))
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    isPessoaFisica () {
      return this.vistoriador.pessoa.type === 1
    },
    isEmpresa () {
      return this.vistoriador.pessoa.type === 2
    }
  },
  watch: {},
  methods: {
    load () {
      this.id && find(this.id || this.vistoriador.id)
        .then(response => {
          let data = response.data
          this.vistoriador = data
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o arremtante, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.vistoriador))

      this.status = 'Processando dados do vistoriador'
      let method = data.id ? updateVistoriador(data.id, data) : newVistoriador(data)
      method
        .then(({data}) => {
          this.vistoriador.id = data.id
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              // this.$refs.timer.start()
              window.setTimeout(() => {
                this.loading = false
                this.dg()
                this.load()
              }, 1)
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          }
          // Processa foto
          /*if (this.deleteFoto) {
            console.log('Deleta Foto')
          } else if (this.$fotoVistoriador) {
            this.status = 'Analisando e processando foto do vistoriador'
            updateFotoVistoriador(this.vistoriador.id, {data: this.$fotoVistoriador, filename: 'foto'})
              .then(({data}) => {
                cbSuccess()
                delete this.$fotoVistoriador
              })
              .catch((error) => {
                alert('Impossível enviar a foto do vistoriador, mas os demais dados foram processados com sucesso!')
                console.error(error)
                cbSuccess()
              })
          } else {
            cbSuccess()
          }*/
          cbSuccess()

        })
        .catch(error => {
          console.log(error)
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    changeDocumento (d) {
      console.log(d)
    }
  },
  components: {
    FormInput,
    PrestadorAssinatura,
    PessoaFoto,
    PessoaInputV2,
    Log,
    Acesso,
    TipoVistoriadorSelect,
    ECol,
    ERow,
    PatioInput,
    StatusSelect,
    DefaultAsyncSelect,
    WindowLoading,
    WindowFooter,
    WindowBody,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    ErpLabel,
    ErpSelect,
    DatetimeInput,
    ErpCheckbox
  }
}
</script>
